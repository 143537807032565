<script setup lang="ts">
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'

defineProps<{
  title: string
  value: string | number
  subtitle?: string
}>()

const vuetifyTheme = useTheme()

const series = [
  {
    data: [200, 200, 500, 500, 300, 300, 100, 100, 450, 450, 650, 650],
  },
]

const chartOptions = computed(() => {
  const currentTheme = vuetifyTheme.current.value.colors

  return {
    chart: {
      height: 90,
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      enabled: false,
    },
    markers: {
      strokeColor: 'transparent',
    },
    colors: [currentTheme.error],
    stroke: {
      width: 3,
    },
    grid: {
      padding: {
        bottom: -10,
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 110,
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 90,
          },
        },
      },
    ],
  }
})
</script>

<template>
  <VCard>
    <VCardText>
      <div>
        <span v-if="subtitle" class="text-disabled text-xs">
          {{ subtitle }}
        </span>

        <h5 class="text-h5">{{ title }}</h5>
      </div>

      <VueApexCharts :options="chartOptions" :series="series" :height="106" />

      <div class="d-flex align-center justify-space-between mt-3">
        <h4 class="text-h4 text-center">
          {{ value }}
        </h4>
      </div>
    </VCardText>
  </VCard>
</template>
