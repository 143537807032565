import { defineStore } from 'pinia'
import axios from '@axios'
import type { HobbyProperties } from '@/types/hobbies'

export const useHobbiesStore = defineStore('HobbiesStore', {
  state: () => ({
    loading: false,
    hobbies: [] as HobbyProperties[],
  }),

  actions: {
    async setHobbies() {
      this.loading = true

      const response = await axios.get<HobbyProperties[]>('/hobbies')

      this.loading = false

      if (response?.status !== 200) return

      this.hobbies = response.data
    },

    createHobby(hobby: HobbyProperties) {
      return axios.post('/hobbies', hobby)
    },

    updateHobby(id: string, hobby: HobbyProperties) {
      return axios.patch(`/hobbies/${id}`, hobby)
    },

    createSubHobby(hobbyId: string, subHobby: HobbyProperties) {
      return axios.post(`/hobbies/${hobbyId}`, subHobby)
    },

    updateSubHobby(id: string, subHobby: HobbyProperties) {
      return axios.patch(`/hobbies/sub/${id}`, subHobby)
    },

    deleteHobby(id: string) {
      return axios.delete(`/hobbies/${id}`)
    },

    deleteSubHobby(id: string) {
      return axios.delete(`/hobbies/sub/${id}`)
    },
  },
})
