<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable'
import { avatarText } from '@/@core/utils/formatters'
import { longDateFormatter } from '@/plugins/util'
import { useMailsStore } from '@/store/mails'
import type { MailProperties } from '@/types/mails'

const mailsStore = useMailsStore()

const messages = ref<MailProperties[]>([])
const isLoading = ref(false)
const selectedMailId = ref<string | null>(null)
const mailDialogVisible = ref(false)

const headers = [
  { title: 'İsim', key: 'fullName', width: 200, sortable: false },
  { title: 'Tarih', key: 'createdAt', width: 200, sortable: false },
  { title: 'Mesaj', key: 'message', sortable: false },
  { title: 'İşlemler', key: 'actions', width: 0, sortable: false },
]

const fetchMails = async () => {
  isLoading.value = true

  const response = await mailsStore.fetchMails({
    q: '',
    itemsPerPage: 10,
    page: 1,
    sortBy: [{ key: 'createdAt', order: 'desc' }],
  })

  isLoading.value = false

  if (response?.status !== 200 || !response.data) return

  messages.value = response.data.data
}

fetchMails()

const getSelectedMail = computed(() => {
  return messages.value?.find((mail) => mail._id === selectedMailId.value)
})
</script>

<template>
  <VCard title="Son E-postalar">
    <template #append>
      <VBtn color="primary" variant="text" to="/mails"> Tümünü Gör </VBtn>
    </template>

    <VDataTableServer
      :items="messages"
      :headers="headers"
      class="text-no-wrap"
      :loading="isLoading"
      no-data-text="Herhangi bir e-posta bulunamadı"
    >
      <template #item.fullName="{ item }">
        <div class="d-flex align-center">
          <VAvatar size="38" color="" variant="tonal" class="me-3">
            {{ avatarText(item.raw.fullName) }}
          </VAvatar>

          <h6 class="text-body-1 font-weight-medium">
            {{ item.raw.fullName }}
          </h6>
        </div>
      </template>

      <template #item.createdAt="{ item }">
        <VChip
          v-if="item.raw.createdAt"
          prepend-icon="tabler-calendar"
          color="primary"
        >
          {{ longDateFormatter(item.raw.createdAt) }}
        </VChip>

        <template v-else>-</template>
      </template>

      <template #item.message="{ item }">
        {{
          item.raw.message?.length > 90
            ? `${item.raw.message?.slice(0, 90)}...`
            : item.raw.message
        }}
      </template>

      <template #item.actions="{ item }">
        <IconBtn
          icon="tabler-eye"
          @click="
            () => {
              selectedMailId = item.raw._id
              mailDialogVisible = true
            }
          "
        />

        <IconBtn
          icon="tabler-arrow-back-up"
          :href="`mailto:${item.raw.email}?subject=${encodeURIComponent(
            'Cvp: Voco İletişim Formu',
          )}`"
        />
      </template>

      <template #bottom></template>
    </VDataTableServer>

    <DialogMail
      v-if="getSelectedMail"
      v-model="mailDialogVisible"
      :mail="getSelectedMail"
      @mail-read="
        (e) => {
          if (getSelectedMail) getSelectedMail.viewed = e
        }
      "
    />
  </VCard>
</template>
