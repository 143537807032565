<script lang="ts" setup>
import { avatarText } from '@/@core/utils/formatters'
import { longDateFormatter } from '@/plugins/util'
import { useMailsStore } from '@/store/mails'
import type { MailProperties } from '@/types/mails'

interface Props {
  modelValue: boolean
  mail: MailProperties
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
  (event: 'mailRead', value: boolean): void
}>()

const mailStore = useMailsStore()

const readMail = async (mailId: string) => {
  if (!props.mail || props.mail.viewed) return

  emit('mailRead', true)

  const response = await mailStore.readMail(mailId)

  if (response?.status !== 200) emit('mailRead', false)
}

watchEffect(() => {
  if (props.modelValue && !props.mail.viewed) readMail(props.mail._id)
})
</script>

<template>
  <VDialog
    :model-value="modelValue"
    width="600"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <DialogCloseBtn @click="$emit('update:modelValue', false)" />

    <VCard title="Mesaj Ayrıntıları">
      <VCardText class="d-flex flex-column">
        <div class="d-flex align-center mb-6">
          <VAvatar size="38" color="" variant="tonal" class="me-3">
            {{ avatarText(mail.fullname) }}
          </VAvatar>

          <div>
            <span class="d-block">{{ mail.fullname }}</span>
            <div class="d-flex align-center flex-wrap gap-2">
              <VChip size="small">
                {{ mail.email }}
              </VChip>

              <VChip size="small">
                {{ mail.tel }}
              </VChip>
            </div>
          </div>
        </div>

        <div
          class="border pa-4 rounded-lg"
          :style="{
            position: 'relative',
          }"
        >
          <span
            v-if="mail.createdAt"
            class="text-disabled"
            :style="{
              background: 'rgb(var(--v-theme-surface))',
              position: 'absolute',
              right: '1rem',
              top: '-0.7rem',
            }"
          >
            {{ longDateFormatter(mail.createdAt) }}
          </span>

          <p class="mb-0">
            {{ mail.message }}
          </p>
        </div>

        <div class="d-flex justify-end gap-4 mt-4">
          <VBtn
            variant="text"
            color="error"
            @click="$emit('update:modelValue', false)"
          >
            İptal
          </VBtn>

          <VBtn
            variant="tonal"
            color="primary"
            :href="`mailto:${mail.email}?subject=${encodeURIComponent(
              'Cvp: Voco İletişim Formu',
            )}`"
          >
            Yanıtla
          </VBtn>
        </div>
      </VCardText>
    </VCard>
  </VDialog>
</template>
