import { createMongoAbility } from '@casl/ability'
import type { UserAbility } from '@/plugins/casl/AppAbility'

const getAccessToken = () => {
  const storedAccessToken = JSON.parse(
    localStorage.getItem('auth-state') ?? '{}',
  )?.accessToken

  if (storedAccessToken) return storedAccessToken

  return null
}

export const initialAbility: UserAbility[] = [
  {
    action: 'read',
    subject: 'auth',
  },
]

export default createMongoAbility(
  getAccessToken() ? [{ action: 'manage', subject: 'all' }] : initialAbility,
)
