import '@/schemas/yup'
import '@/@iconify/icons-bundle'
import { abilitiesPlugin } from '@casl/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { vMaska } from 'maska'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import * as Sentry from '@sentry/vue'
import App from '@/App.vue'
import ability from '@/plugins/casl/ability'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'

// Style
import '@core/scss/template/index.scss'
import '@styles/styles.scss'

loadFonts()

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://f1f5867c10da7c263b63255941e85912@o4506067415072768.ingest.sentry.io/4506082007515136',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/admin(-test)?.vocoapp\.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

app.directive('maska', vMaska)

app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(layoutsPlugin)
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})

app.use(VueGoogleMaps, {
  load: {
    key: import.meta.env.VITE_MAPS_API_KEY,
    libraries: 'places',
  },
})

app.mount('#app')
