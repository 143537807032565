import { defineStore } from 'pinia'
import axios from '@axios'
import type { StatisticsProperties } from '@/types/statistics'

export type Period = 'monthly' | 'weekly' | 'daily' | 'annually'

export interface StatisticsParams {
  period: Period
  start: string
  end: string
  startRaw: string
}

export interface FetchParams {
  q: string
  page: number
  itemsPerPage: number
  date?: string
  sortBy: { key: string; order: 'asc' | 'desc' }[]
}

export const useStatisticsStore = defineStore('StatisticsStore', {
  actions: {
    fetchStatistics(params: StatisticsParams) {
      return axios.get<StatisticsProperties>('/statistics', { params })
    },

    fetchAllSales(params: FetchParams) {
      return axios.get('/sales', { params })
    },

    exportSales(params: { start: Date; end: Date }) {
      return axios.get('/sales/export', { params })
    },
  },
})
