import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import nprogress from 'nprogress'
import { useTitle } from '@vueuse/core'
import { canNavigate } from '@/@layouts/plugins/casl'
import { authStorage } from '@/store/auth'
import routes from '~pages'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [...setupLayouts(routes)],
})

router.beforeEach((to) => {
  const authStore = authStorage.value
  const isLoggedIn = authStore.accessToken && !!authStore.user

  if (to.meta.title) useTitle(`${to.meta.title} - Voco Admin`)
  else useTitle('Voco Admin')

  if (canNavigate(to)) {
    if (to.meta.redirectIfLoggedIn && isLoggedIn) return '/'
  } else if (isLoggedIn)
    return {
      name: 'not-authorized',
    }
  else
    return {
      name: 'login',
      query: { to: to.name !== 'index' ? to.fullPath : undefined },
    }
})

router.beforeResolve((to, _, next) => {
  if (to.name) nprogress.start()
  next()
})

router.afterEach(() => {
  if (nprogress.isStarted()) nprogress.done(true)
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

export default router
