import { defineStore } from 'pinia'
import axios from '@axios'
import type { PackageProperties } from '@/types/packages'

const PROFILE_API_BASE = import.meta.env.VITE_AXIOS_BASE_URL

export const usePackagesStore = defineStore('PackagesStore', {
  state: () => ({
    loading: false,
    packages: [] as PackageProperties[],
  }),

  actions: {
    async setPackages() {
      this.loading = true

      const response = await axios.get<PackageProperties[]>(
        `${PROFILE_API_BASE}/premium/getPremiumPackages`,
      )

      this.loading = false

      if (response?.status !== 200) return

      this.packages = response.data
    },

    createPackage(packageData: PackageProperties) {
      return axios.post(
        `${PROFILE_API_BASE}/premium/createPackage`,
        packageData,
      )
    },

    updatePackage(packageData: PackageProperties) {
      return axios.post(
        `${PROFILE_API_BASE}/premium/updatePackage`,
        packageData,
      )
    },

    deletePackage(tierId: string) {
      return axios.post(`${PROFILE_API_BASE}/premium/$${tierId}`)
    },
  },
})
