import { defineStore } from 'pinia'
import axios from '@axios'

export interface InterestProperties {
  _id?: string
  tr: string
  en: string
}

export const useInterestsStore = defineStore('InterestsStore', {
  state: () => ({
    loading: false,
    interests: [] as InterestProperties[],
  }),

  actions: {
    async setInterests() {
      this.loading = true

      const response = await axios.get<InterestProperties[]>('/lookingFor')

      this.loading = false

      if (response?.status !== 200) return

      this.interests = response.data
    },

    addInterest(lookingFor: InterestProperties) {
      return axios.post('/lookingFor', lookingFor)
    },

    updateInterest(id: string, lookingFor: InterestProperties) {
      return axios.post(`/lookingFor/${id}`, lookingFor)
    },

    deleteInterest(id: string) {
      return axios.delete(`/lookingFor/${id}`)
    },
  },
})
