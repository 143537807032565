<script setup lang="ts">
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'
import { getRandomBetween } from '@/plugins/util'

defineProps<{
  title: string
  value: string | number
  subtitle?: string
}>()

const vuetifyTheme = useTheme()
const currentTheme = vuetifyTheme.current.value.colors

const series = [
  {
    data: [
      getRandomBetween(200, 300),
      getRandomBetween(50, 150),
      getRandomBetween(400, 700),
      getRandomBetween(400, 500),
    ],
  },
]

const chartOptions = {
  chart: {
    type: 'area',
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  markers: {
    colors: 'transparent',
    strokeColors: 'transparent',
  },
  grid: {
    show: false,
  },
  colors: [currentTheme.success],
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 0.8,
      opacityFrom: 0.6,
      opacityTo: 0.1,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 2,
    curve: 'smooth',
  },
  xaxis: {
    show: true,
    lines: {
      show: false,
    },
    labels: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    axisBorder: {
      show: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  yaxis: {
    stroke: {
      width: 0,
    },
    show: false,
  },
  responsive: [
    {
      breakpoint: 1387,
      options: {
        chart: {
          height: 80,
        },
      },
    },
    {
      breakpoint: 1200,
      options: {
        chart: {
          height: 120,
        },
      },
    },
  ],
}
</script>

<template>
  <VCard>
    <VCardText class="d-flex justify-space-between">
      <div>
        <div class="mb-1">
          <span v-if="subtitle" class="text-xs text-disabled">
            {{ subtitle }}
          </span>

          <h5 class="text-h5">
            {{ title }}
          </h5>
        </div>

        <h4 class="text-h4">
          {{ value }}
        </h4>
      </div>

      <IconBtn color="success" variant="tonal" icon="tabler-users" />
    </VCardText>

    <VueApexCharts :options="chartOptions" :series="series" :height="114" />
  </VCard>
</template>
