import { defineStore } from 'pinia'
import axios from '@axios'

export interface FetchParams {
  q: string
  page: number
  itemsPerPage: number
  sortBy: { key: string; order: 'asc' | 'desc' }[]
}

export const useMailsStore = defineStore('MailsStore', {
  actions: {
    fetchMails(params: FetchParams) {
      return axios.get('/mails', { params })
    },

    readMail(mailId: string) {
      return axios.patch(`/mails/${mailId}`)
    },
  },
})
