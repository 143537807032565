<script setup lang="ts">
import { useUserStore } from '@/store/users'

const emit = defineEmits<{
  (
    e: 'setCount',
    d: { male: number; female: number; total: number; live: number },
  ): void
}>()

const userStore = useUserStore()

const loading = ref(false)

const userCount = reactive({
  male: 0,
  female: 0,
  testmale: 0,
  testfemale: 0,
  total: 0,
  live: 0,
  notSpecified: 0,
  isTestUser: 0,
})

const fetchUserCounts = async () => {
  loading.value = true

  const response = await userStore.fetchUserCount()

  loading.value = false

  if (response?.status !== 200) return

  userCount.male = response.data?.male ?? 0
  userCount.female = response.data?.female ?? 0
  userCount.total = response.data?.total ?? 0
  userCount.live = response.data?.live ?? 0
  userCount.notSpecified = response.data?.notSpecified ?? 0
  userCount.testmale = response.data?.testmale ?? 0
  userCount.testfemale = response.data?.testfemale ?? 0

  emit('setCount', toRaw(userCount))
}

fetchUserCounts()

useIntervalFn(() => {
  fetchUserCounts()
}, 30 * 1000)

const staticData = computed(() => [
  {
    title: 'Kayıtlı Erkek Kullanıcı',
    stats: userCount.male,
    color: 'primary',
    icon: 'tabler-gender-male',
  },
  {
    title: 'Kayıtlı Kadın Kullanıcı',
    stats: userCount.female,
    color: '#db2777',
    icon: 'tabler-gender-female',
  },
  {
    title: 'Kayıtlı Belirsiz Kullanıcı',
    stats: userCount.notSpecified,
    color: 'gray',
    icon: 'icons8:gender',
  },
  {
    title: 'Toplam Kullanıcı',
    stats: userCount.total,
    color: 'success',
    icon: 'tabler-gender-bigender',
  },
  {
    title: 'Canlı Kullanıcı Sayısı',
    stats: userCount.live,
    color: 'warning',
    icon: 'tabler-live-photo',
  },
  {
    title: 'Erkek Test Kullanıcı Sayısı',
    stats: userCount.testmale,
    color: 'secondary',
    icon: 'fluent-mdl2:test-user-solid',
  },
  {
    title: 'Kadın Test Kullanıcı Sayısı',
    stats: userCount.testfemale,
    color: 'secondary',
    icon: 'fluent-mdl2:test-user-solid',
  },
])
</script>

<template>
  <VRow>
    <VCol v-for="data in staticData" :key="data.title" cols="12" sm="6" lg="3">
      <VCard>
        <VCardText class="d-flex justify-space-between">
          <div>
            <p style="height: 39.5px">{{ data.title }}</p>

            <h6 class="text-h4 mt-1">
              <VProgressCircular
                v-if="loading"
                indeterminate
                width="2"
                size="26"
              />

              <template v-else> {{ data.stats }}</template>
            </h6>
          </div>

          <VAvatar
            rounded
            variant="tonal"
            :color="data.color"
            :icon="data.icon"
          />
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>
