import { defineStore } from 'pinia'
import axios from '@axios'

export interface CategoryProperties {
  _id?: string
  tr: string
  en: string
  index?: number
}


export const useCategoriesStore = defineStore('CategoriesStore', {
  state: () => ({
    loading: false,
    categories: [] as CategoryProperties[],
    businessCategories: [] as CategoryProperties[],
  }),

  actions: {
    async setCategories() {
      this.loading = true

      const response = await axios.get<CategoryProperties[]>(
        '/interests/categories',
      )

      this.loading = false

      if (response?.status !== 200) return

      this.categories = response.data
    },
    async setBusinessCategories() {
      this.loading = true

      const response = await axios.get<CategoryProperties[]>(
        '/company/categories',
      )

      this.loading = false

      if (response?.status !== 200) return

      this.businessCategories = response.data
    },

    addCategory(category: CategoryProperties) {
      return axios.post('/interests/categories', category)
    },

    updateCategory(categoryId: string, category: CategoryProperties) {
      return axios.post(`/interests/categories/${categoryId}`, category)
    },

    deleteCategory(categoryId: string) {
      return axios.delete(`/interests/categories/${categoryId}`)
    },
    addBusinessCategory(category: CategoryProperties) {
      return axios.post('/company/categories', category)
    },

    updateBusinessCategory(categoryId: string, category: CategoryProperties) {
      return axios.post(`/company/categories/${categoryId}`, category)
    },

    deleteBusinessCategory(categoryId: string) {
      return axios.delete(`/company/categories/${categoryId}`)
    },
  },
})
