<script setup lang="ts">
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'
import { hexToRgb } from '@layouts/utils'
import { turkishLiraFormatter } from '@/plugins/util'
import type { StatisticsProperties } from '@/types/statistics'
import type { Period } from '@/store/statistics'

const props = defineProps<{
  period: string
  renderKey: number
  modelValue: Period
  loading?: boolean
  chartData?: StatisticsProperties['sales']
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: Period): void
}>()

const vuetifyTheme = useTheme()

const getReadableMonthName = (monthNumber: string) => {
  if (props.modelValue !== 'monthly') return monthNumber

  return [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ][Number(monthNumber) - 1]
}

const names = computed(
  () => props.chartData?.map((sale) => getReadableMonthName(sale.text)) ?? [],
)

const getSalesPercentage = computed(() => [
  {
    name: 'Satış',
    data: props.chartData?.map((sale) => Number(sale.value.toFixed(2))) ?? [],
  },
])

const currentSales = computed(() => {
  if (!props.chartData) return 0

  return props.chartData.find((sale) => sale.isCurrent)?.value ?? 0
})

const chartOptions = computed(() => {
  const currentTheme = vuetifyTheme.current.value.colors
  const variableTheme = vuetifyTheme.current.value.variables

  return {
    chart: {
      parentHeightOffset: 0,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: '60%',
        columnWidth: '50%',
        startingShape: 'rounded',
        endingShape: 'rounded',
        borderRadius: 4,
        distributed: true,
      },
    },
    grid: {
      show: false,
      padding: {
        top: -30,
        bottom: 0,
        left: -10,
        right: -10,
      },
    },
    colors: [
      `rgba(${hexToRgb(currentTheme.primary)},${
        variableTheme['dragged-opacity']
      })`,
      `rgba(${hexToRgb(currentTheme.primary)},${
        variableTheme['dragged-opacity']
      })`,
      `rgba(${hexToRgb(currentTheme.primary)},${
        variableTheme['dragged-opacity']
      })`,
      `rgba(${hexToRgb(currentTheme.primary)},${
        variableTheme['dragged-opacity']
      })`,
      `rgba(${hexToRgb(currentTheme.primary)}, 1)`,
    ].slice(names.value ? -names.value.length : 0),
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: names.value.length ? names.value : [''],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: `rgba(${hexToRgb(currentTheme['on-surface'])},${
            variableTheme['disabled-opacity']
          })`,
          fontSize: '13px',
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      theme: vuetifyTheme.global.current.value.dark ? 'dark' : 'light',
    },
    responsive: [
      {
        breakpoint: 1025,
        options: {
          chart: {
            height: 147,
          },
        },
      },
    ],
  }
})

const earningsReports = computed(() => [
  {
    color: 'primary',
    icon: 'tabler-currency-dollar',
    title: 'Satışlar',
    amount: currentSales.value,
  },
])
</script>

<template>
  <VCard class="h-100">
    <VCardItem class="pb-0">
      <div class="d-flex justify-space-between">
        <div>
          <VCardTitle>{{ period }} Satışlar</VCardTitle>
          <VCardSubtitle>{{ period }} Satış Tablosu</VCardSubtitle>
        </div>

        <VMenu>
          <template #activator="{ props: menuProps }">
            <VBtn
              append-icon="tabler-chevron-down"
              variant="tonal"
              :loading="loading"
              v-bind="menuProps"
            >
              {{ period }}
            </VBtn>
          </template>

          <VList>
            <VListItem
              v-for="item in [
                {
                  title: 'Günlük',
                  value: 'daily',
                },
                {
                  title: 'Haftalık',
                  value: 'weekly',
                },
                {
                  title: 'Aylık',
                  value: 'monthly',
                },
                {
                  title: 'Yıllık',
                  value: 'annually',
                },
              ]"
              :key="item.value"
              :active="item.value === modelValue"
              :title="item.title"
              @click="() => emit('update:modelValue', item.value as Period)"
            />
          </VList>
        </VMenu>
      </div>
    </VCardItem>

    <VCardText class="mt-12">
      <VRow>
        <VCol cols="12" sm="4" class="d-flex flex-column align-self-end">
          <div class="d-flex align-center gap-2 mb-2 pb-1 flex-wrap">
            <h4 class="text-h1">
              {{ turkishLiraFormatter(currentSales || 0) }}
            </h4>
          </div>

          <span class="text-sm text-disabled">
            Dönemlere göre gösterilen performans
          </span>
        </VCol>

        <VCol cols="12" sm="8" class="pt-0 position-relative">
          <VueApexCharts
            :key="`${period}${renderKey}`"
            :options="chartOptions"
            :series="getSalesPercentage"
            height="145"
          />

          <div
            v-if="names?.length === 0"
            class="position-absolute inset-0 d-flex align-center justify-center"
          >
            <span class="text-sm text-secondary">Veri bulunamadı.</span>
          </div>
        </VCol>
      </VRow>

      <div class="border rounded mt-6 pa-4">
        <VRow>
          <VCol
            v-for="report in earningsReports"
            :key="report.title"
            cols="12"
            sm="4"
          >
            <div class="d-flex align-center">
              <VAvatar
                rounded
                size="26"
                :color="report.color"
                variant="tonal"
                class="me-2"
              >
                <VIcon size="18" :icon="report.icon" />
              </VAvatar>

              <h6 class="text-base font-weight-medium">
                {{ report.title }}
              </h6>
            </div>

            <h6 class="text-h4 mt-2">
              {{ turkishLiraFormatter(report.amount || 0) }}
            </h6>
          </VCol>
        </VRow>
      </div>
    </VCardText>
  </VCard>
</template>
