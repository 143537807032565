import { defineStore } from 'pinia'
import axios from '@axios'
import type {
  FullUserData,
  InfluencerProperties,
  ProfileData,
  UserEventProperties,
} from '@/types/user'

const PROFILE_API_BASE = import.meta.env.VITE_AXIOS_BASE_URL
const ACTIVITY_API_BASE = import.meta.env.VITE_ACTIVITY_BASE_URL

export interface FetchParams extends BaseFetchParams {
  type: string[]
  gender: 'all' | 'female' | 'male'
  membershipType: 'all' | 'FREE' | 'PREMIUM'
  city?: string
  coordinates?: number[]
  hasBannedWords?:string[]
  start?: string
  end?: string
  hasPendingRequests?: boolean | string
}
export interface GiftPremiumParams {
  packageType:"PREMIUM_WEEKLY" | "PREMIUM_MONTHLY" | "PREMIUM_THREE_MONTHS"
}
export interface GiftAIPlusParams {
  packageType:"AI_PLUS_WEEKLY" | "AI_PLUS_MONTHLY" | "AI_PLUS_THREE_MONTHS"
}

export interface BaseFetchParams {
  status?: 'all' | 'active' | 'expired'
  q: string
  page: number
  itemsPerPage: number
  sortBy: { key: string; order: 'asc' | 'desc' }[]
  city?: string
}

export interface VocoFetchParams {
  type?: string[] | undefined
  gender: 'all' | 'female' | 'male'
  category?: string
  hourRange?:string
  hasBannedWords?:string[]
  q: string
  page: number
  itemsPerPage: number
  sortBy: string
  order:"asc" | "desc"
  city?: string | null
  minDate:string | Date
  maxDate:string | Date
}

export const useUserStore = defineStore('UserStore', {
  actions: {
    fetchUsers(params: FetchParams) {
      return axios.get<{ data: ProfileData[]; total: number }>('/users', {
        params,
      })
    },

    exportUsers(params: { start: Date | string; end: Date | string }) {
      return axios.get('/users/export', { params })
    },

    fetchInfluencers(params: BaseFetchParams) {
      return axios.get('/influencers', { params })
    },

    exportInfluencers(params: { start: Date | string; end: Date | string }) {
      return axios.get('/influencers/export', { params })
    },

    fetchUser(userId: string) {
      return axios.get<ProfileData & { influencer: InfluencerProperties }>(
        `/users/${userId}`,
      )
    },

    fetchUserRooms(userId: string) {
      return axios.get(`/users/${userId}/rooms`)
    },

    fetchUserRequests(userId: string) {
      return axios.get(`/users/${userId}/requests`)
    },

    deleteUser(userId: string) {
      return axios.post(`${PROFILE_API_BASE}/user/admin/deleteUser`, {
        userId,
      })
    },

    banUser(userId: string) {
      return axios.post(`${PROFILE_API_BASE}/user/admin/banUser`, {
        userId,
      })
    },
    unbanUser(userId: string) {
      return axios.post(`${PROFILE_API_BASE}/user/admin/unbanUser`, {
        userId,
      })
    },

    fetchUserCount() {
      return axios.get<{
        male: number
        female: number
        testmale: number
        testfemale: number
        total: number
        notSpecified: number
        live: number
      }>('/users/count')
    },

    fetchUserEvents(userId: string, params: BaseFetchParams) {
      return axios.get<{ data: UserEventProperties[]; total: number }>(
        `/users/${userId}/events`,
        { params },
      )
    },

    fetchAllUserEvents(params: VocoFetchParams) {
      return axios.get<{ data: UserEventProperties[]; total: number }>(
        '/users/events',
        { params },
      )
    },

    fetchEventsByCity(params: BaseFetchParams) {
      return axios.get<{ data: UserEventProperties[]; total: number }>(
        '/users/events/cities',
        { params },
      )
    },

    fetchCategoriesByCity(city: string, params: BaseFetchParams) {
      return axios.get<{ data: UserEventProperties[]; total: number }>(
        `/users/events/cities/${city}/categories`,
        { params },
      )
    },

    fetchCategoriesByDistrict(city: string, district: string, params: BaseFetchParams) {
      return axios.get<{ data: UserEventProperties[]; total: number }>(
        `/users/events/cities/${city}/${district}/categories`,
        { params },
      )
    },

    fetchEventsByDistrict(city: string, params: BaseFetchParams) {
      return axios.get<{ data: UserEventProperties[]; total: number }>(
        `/users/events/cities/${city}`,
        { params },
      )
    },

    fetchEventsDetailsByDistrict(city: string, district: string, params: VocoFetchParams) {
      return axios.get<{ data: UserEventProperties[]; total: number }>(
        `/users/events/cities/${city}/${district}`,
        { params },
      )
    },

    exportUserEvents(params: { start: Date | string; end: Date | string }) {
      return axios.get('/users/events/export', { params })
    },

    createUser(data: Partial<FullUserData>) {
      return axios.post(`${PROFILE_API_BASE}/user/admin/createUser`, data)
    },

    editUser(data: Partial<FullUserData>) {
      return axios.post(`${PROFILE_API_BASE}/user/admin/updateUser`, data)
    },

    uploadUserPhoto(userId: string, formData: FormData) {
      return axios.postForm(
        `${PROFILE_API_BASE}/user/admin/${userId}/uploadPhoto`,
        formData,
      )
    },

    checkUsername(username: string) {
      return axios.get(`${PROFILE_API_BASE}/user/isUsernameTaken`, {
        params: { username },
      })
    },

    createUserEvent(data: Partial<UserEventProperties>) {
      return axios.post(
        `${ACTIVITY_API_BASE}/activity/admin/createActivityForSpecificUser`,
        data,
      )
    },
    timeBanUser(userId:string, date:Date){
      return axios.patch(`${PROFILE_API_BASE}/user/timeBanUser/${userId}`, date)
    },
    timeUnbanUser(userId:string){
      return axios.patch(`${PROFILE_API_BASE}/user/timeUnbanUser/${userId}`)
    },
    deleteUserEvent(eventId: string) {
      return axios.delete(
        `${ACTIVITY_API_BASE}/activity/admin/deleteActivity`,
        {
          data: {
            activityId: eventId,
          },
        },
      )
    },

    setInfluencerPaymentStatus(data: {
      userId: string
      year: string
      month: string
      status: 'WAITING' | 'PAID'
    }) {
      return axios.post(
        `${PROFILE_API_BASE}/user/setInfluencerPaymentStatus`,
        data,
      )
    },

    generateAIProfileForTestUser(data: any) {
      return axios.patch(
        `${PROFILE_API_BASE}/corporate/admin/generateAIProfileForTestUser`,
        data,
      )
    },
    createAIPlusPackageForTestUser(data: any) {
      return axios.patch(
        `${PROFILE_API_BASE}/corporate/admin/createAIPlusPackageForTestUser`,
        data,
      )
    },
    giftPremiumToUser(data:GiftPremiumParams, userId:string){
      return axios.post(`${PROFILE_API_BASE}/premium/user/${userId}/giftPremium`,data)
    },
    giftAIPlusToUser(data:GiftAIPlusParams, userId:string){
      return axios.post(`${PROFILE_API_BASE}/premium/user/${userId}/giftAIPlus`,data)
    }
  },
})
