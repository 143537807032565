import { defineStore } from 'pinia'
import type { Period } from '@/store/statistics'

export const globalData = useStorage('admin-app-data', {
  dashboardPeriod: 'monthly' as Period,
  reportViews: ['daily-range'] as string[],
})

export const useGlobalStore = defineStore('GlobalStore', {
  state: () => ({
    loading: false,
  }),
})
