<script setup lang="ts">
import { Toaster } from 'vue-sonner'
import { useTheme } from 'vuetify'
import NProgress from 'nprogress'
import ScrollToTop from '@core/components/ScrollToTop.vue'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import 'nprogress/nprogress.css'

// Store
import { fetchUser } from '@/store/auth'
import { useHobbiesStore } from '@/store/hobbies'
import { useCategoriesStore } from '@/store/categories'
import { useInterestsStore } from '@/store/interests'
import { usePackagesStore } from '@/store/packages'

NProgress.configure({ showSpinner: false })

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  handleSkinChanges,
} = useThemeConfig()

const { global } = useTheme()

const { setHobbies } = useHobbiesStore()
const { setCategories } = useCategoriesStore()
const { setInterests } = useInterestsStore()
const { setPackages } = usePackagesStore()

// Theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()
handleSkinChanges()

// App
fetchUser().then((i) => {
  if (!i) return

  setHobbies()
  setCategories()
  setInterests()
  setPackages()
})
</script>

<template>
  <VApp
    :style="`--v-global-theme-primary: ${hexToRgb(
      global.current.value.colors.primary,
    )}`"
  >
    <RouterView />
    <ScrollToTop />
    <Toaster :theme="$vuetify.theme.name" rich-colors />
  </VApp>
</template>

<style>
.pac-container {
  z-index: 9999;
}
</style>
