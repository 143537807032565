/* Relative time formatter */
const IntlRelativeTimeFormatter = new Intl.RelativeTimeFormat('tr', {
  style: 'short',
})

const DIVISIONS = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
]

export const relativeTimeFormatter = (date: Date) => {
  let duration = (date.getTime() - new Date().getTime()) / 1000

  for (const division of DIVISIONS) {
    if (Math.abs(duration) < division.amount)
      return IntlRelativeTimeFormatter.format(
        Math.round(duration),
        division.name as Intl.RelativeTimeFormatUnit,
      )

    duration /= division.amount
  }
}

/* Number formatter */
const IntlNumberFormatter = new Intl.NumberFormat('tr-TR')

export const numberFormatter = (value: number) => {
  return IntlNumberFormatter.format(value)
}

/* Turkish lira formatter */
const IntlTryFormatter = new Intl.NumberFormat('tr-TR', {
  style: 'currency',
  currency: 'TRY',
  minimumFractionDigits: 2,
})

/* USD formatter */
const IntlUsdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

/* USD formatter */
const IntlEurFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
})

export const turkishLiraFormatter = (value: number | string) => {
  return IntlTryFormatter.format(
    typeof value === 'string' ? Number(value) : value,
  )
}

export const usdFormatter = (value: number | string) => {
  return IntlUsdFormatter.format(
    typeof value === 'string' ? Number(value) : value,
  )
}
export const eurFormatter = (value: number | string) => {
  return IntlEurFormatter.format(
    typeof value === 'string' ? Number(value) : value,
  )
}

/* Long date formatter */
const IntlDateFormatter = new Intl.DateTimeFormat('tr-TR', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
})
const IntlDateFormatterWithoutTime = new Intl.DateTimeFormat('tr-TR', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
})

export const longDateFormatter = (date: Date | string) => {
  if (!date) return

  try {
    return IntlDateFormatter.format(
      typeof date === 'string' ? new Date(date) : date,
    )
  } catch {
    return date.toLocaleString('tr-TR')
  }
}
export const longDateTimeFormatter = (date: Date | string) => {
  if (!date) return

  try {
    return IntlDateFormatterWithoutTime.format(
      typeof date === 'string' ? new Date(date) : date,
    )
  } catch {
    return date.toLocaleString('tr-TR')
  }
}

export const getRandomBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const subMinValueAndMultiply = (
  value: number,
  minValue: number,
  actualPrice: number,
) => (value - minValue) * actualPrice

export const paginationMeta = computed(() => {
  return <T extends { page: number; itemsPerPage: number }>(
    options: T,
    total: number,
  ) => {
    const start = (options.page - 1) * options.itemsPerPage + 1
    const end = Math.min(options.page * options.itemsPerPage, total)

    return `${total} kayıttan ${start}-${end} arasındaki kayıtlar gösteriliyor`
  }
})
