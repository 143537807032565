<script lang="ts" setup>
import { useSkins } from '@core/composable/useSkins'

// @layouts plugin
const Layout = defineAsyncComponent(() => import('./components/Layout.vue'))

const { layoutAttrs, injectSkinClasses } = useSkins()

injectSkinClasses()
</script>

<template>
  <Layout v-bind="layoutAttrs" />
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use "@layouts/styles/default-layout";
</style>
