import axios from '@axios'
import router from '@/router'
import ability, { initialAbility } from '@/plugins/casl/ability'
import type { LoginResponse, User } from '@/types/auth'

const initialState = {
  accessToken: '',
  user: {} as User,
}

const authBase = import.meta.env.VITE_AXIOS_BASE_URL

export const authStorage = useStorage('auth-state', initialState)

export const reset = (redirect?: boolean) => {
  authStorage.value = initialState
  ability.update(initialAbility)

  if (redirect) router.push('/login')
}

export const fetchUser = async () => {
  if (!authStorage.value.accessToken) {
    reset(true)

    return
  }

  const response = await axios.get<User>(`${authBase}/user/profile`)

  if (response?.status === 200) {
    authStorage.value.user = response.data
    ability.update([{ action: 'manage', subject: 'all' }])

    return true
  }

  return false
}

export const send2faCode = async (phone: string) => {
  const response = await axios.post(`${authBase}/auth/sendSmsVerification`, {
    phone,
    isAcceptedTerms: true,
  })

  return response?.status === 201
}

export const login = async ({
  phone,
  digitCode,
}: {
  phone: string
  digitCode: string
}) => {
  const response = await axios.post<LoginResponse>(
    `${authBase}/auth/admin/2fa/verifyAuthentication`,
    {
      phone,
      digitCode,
    },
  )

  if (response?.status === 201) {
    authStorage.value.accessToken = response.data.access_token

    const userFetched = await fetchUser()

    if (userFetched) {
      ability.update([{ action: 'manage', subject: 'all' }])

      return true
    }

    return false
  }

  return false
}

export const logout = () => {
  authStorage.value = initialState
  ability.update(initialAbility)
  router.push('/login')
}

export default {
  login,
  logout,
  reset,
  fetchUser,
  send2faCode,
}
