<script lang="ts" setup>
import { startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns'
import { numberFormatter } from '@/plugins/util'
import { globalData, useGlobalStore } from '@/store/global'
import type { StatisticsProperties } from '@/types/statistics'
import { type Period, useStatisticsStore } from '@/store/statistics'

const loadingStore = useGlobalStore()
const statisticsStore = useStatisticsStore()

const currentPeriod = ref<Period>(
  globalData.value?.dashboardPeriod ?? 'monthly',
)

const statistics = ref<StatisticsProperties>()
const loading = ref(false)
const renderKey = ref(0)

loadingStore.loading = true

const getPeriodInDates = (period: Period) => {
  const now = new Date()
  const start = new Date()

  let startRaw: Date

  switch (period) {
    case 'daily':
      start.setDate(now.getDate() - 6)
      startRaw = startOfDay(now)
      break
    case 'monthly':
      start.setMonth(now.getMonth() - 5)
      startRaw = startOfMonth(now)
      break
    case 'weekly':
      start.setDate(now.getDate() - 7 * 5)
      startRaw = startOfWeek(now, { weekStartsOn: 1 })
      break
    case 'annually':
      start.setFullYear(now.getFullYear() - 5)
      startRaw = startOfYear(now)
      break
  }

  return {
    start,
    startRaw,
    end: now,
  }
}

const fetchStatistics = async (period: Period) => {
  const periodInDates = getPeriodInDates(period)

  loading.value = true

  const response = await statisticsStore.fetchStatistics({
    period,
    start: periodInDates.start.toISOString(),
    end: periodInDates.end.toISOString(),
    startRaw: periodInDates.startRaw.toISOString(),
  })

  loading.value = false
  loadingStore.loading = false

  if (response?.status !== 200) return

  if (period === currentPeriod.value) renderKey.value += 1
  currentPeriod.value = period
  statistics.value = response.data
  globalData.value.dashboardPeriod = period
}

fetchStatistics(currentPeriod.value)

const readablePeriod = computed(() => {
  const status = currentPeriod.value

  if (status === 'weekly') return 'Haftalık'
  else if (status === 'annually') return 'Yıllık'
  else if (status === 'daily') return 'Günlük'
  else return 'Aylık'
})

const staticStats = computed(() => [
  {
    icon: 'tabler-home-check',
    color: 'primary',
    title: 'Kayıtlı Kurum',
    stat: statistics.value?.activeCorporates ?? 0,
  },
  {
    icon: 'tabler-home-question',
    color: 'error',
    title: 'Bekleyen Kurum',
    stat: statistics.value?.pendingCorporates ?? 0,
  },
  {
    icon: 'tabler-rocket',
    color: 'warning',
    title: 'Aktif Pro Etkinlik',
    stat: statistics.value?.activeProEvents ?? 0,
  },
  {
    icon: 'tabler-confetti',
    color: 'warning',
    title: 'Aktif Voco',
    stat: statistics.value?.activeSocialEvents ?? 0,
  },
])
</script>

<template>
  <div v-if="!loadingStore.loading">
    <LiveUserInformation />
    <VRow>
      <VCol cols="12" md="6">
        <VRow>
          <VCol cols="12">
            <VCard>
              <VCardText>
                <VRow>
                  <VCol
                    v-for="item in staticStats"
                    :key="item.title"
                    cols="12"
                    md="6"
                    lg="3"
                  >
                    <div class="d-flex flex-column gap-2">
                      <VAvatar
                        :color="item.color"
                        variant="tonal"
                        class="me-3"
                        size="small"
                      >
                        <VIcon :icon="item.icon" size="small" />
                      </VAvatar>

                      <div class="d-flex flex-column">
                        <span class="text-h4 font-weight-medium">
                          {{
                            typeof item.stat === 'number'
                              ? numberFormatter(Number(item.stat))
                              : item.stat
                          }}
                        </span>

                        <span class="text-sm">
                          {{ item.title }}
                        </span>
                      </div>
                    </div>
                  </VCol>
                </VRow>
              </VCardText>
            </VCard>
          </VCol>

          <VCol cols="12" md="6">
            <WidgetImpressions
              :title="`${readablePeriod} Görüntülenme`"
              :value="numberFormatter(statistics?.views ?? 0)"
              subtitle="Voco Pro"
            />
          </VCol>

          <VCol cols="12" md="6">
            <WidgetUsersChart
              :title="`${readablePeriod} Katılım`"
              :value="numberFormatter(statistics?.participants ?? 0)"
              subtitle="Voco Pro"
            />
          </VCol>
        </VRow>
      </VCol>

      <VCol cols="12" md="6">
        <WidgetSpendingsChart
          :model-value="currentPeriod"
          :loading="loading"
          :render-key="renderKey"
          :chart-data="statistics?.sales ?? []"
          :period="readablePeriod"
          @update:model-value="fetchStatistics($event)"
        />
      </VCol>

      <VCol cols="12">
        <LastMails />
      </VCol>
    </VRow>
  </div>
</template>

<route lang="yaml">
meta:
  title: Özet
</route>
