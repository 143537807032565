/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: '${path} alanı gereklidir',
    notType: '${path} alanı geçersiz',
    notNull: '${path} alanı boş olamaz',
    notOneOf: '${path} alanı belirlenen formata uymuyor',
    oneOf: '${path} alanı sadece şunlardan biri olabilir: ${values}',
  },
  string: {
    min: '${path} alanı en az ${min} karakter olmalıdır',
    max: '${path} alanı en fazla ${max} karakter olmalıdır',
    email: '${path} alanı geçerli bir e-posta adresi olmalıdır',
    url: '${path} alanı geçerli bir URL olmalıdır',
    trim: '${path} alanı boşluk karakterleriyle başlayamaz veya bitmez',
    lowercase: '${path} alanı küçük harf içermelidir',
    uppercase: '${path} alanı büyük harf içermelidir',
    matches: '${path} alanı belirlenen formata uymuyor',
  },
  number: {
    lessThan: '${path} alanı ${less} değerinden küçük olmalıdır',
    moreThan: '${path} alanı ${more} değerinden büyük olmalıdır',
    min: '${path} alanı en az ${min} olmalıdır',
    max: '${path} alanı en fazla ${max} olmalıdır',
    positive: '${path} alanı pozitif bir sayı olmalıdır',
    negative: '${path} alanı negatif bir sayı olmalıdır',
    integer: '${path} alanı bir tam sayı olmalıdır',
  },
  array: {
    min: '${path} alanı en az ${min} öğe içermelidir',
    max: '${path} alanı en fazla ${max} öğe içermelidir',
    length: '${path} alanı ${length} öğe içermelidir',
  },
  boolean: {
    isValue: '${path} alanı ${value} değerine sahip olmalıdır',
  },
  object: {
    noUnknown: '${path} alanı şu alanlardan oluşmalıdır: ${unknown}',
  },
})
