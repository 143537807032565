import axios from 'axios'
import { toast } from 'vue-sonner'
import nprogress from 'nprogress'
import router from '@/router'
import { authStorage } from '@/store/auth'

const axiosIns = axios.create({
  baseURL: import.meta.env.VITE_AXIOS_BASE_URL
    ? `${import.meta.env.VITE_AXIOS_BASE_URL}/corporate/admin`
    : '',
  timeout: 30000,
})

axiosIns.interceptors.request.use((config) => {
  const token = authStorage?.value?.accessToken

  nprogress.start()
  if (token) {
    config.headers = config.headers || {}

    if (!config.headers.Authorization)
      config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

const pagesShouldNotRedirect = [
  'index',
  'login',
  'not-authorized',
  'logout',
  'forgot-password',
  'reset-password',
]

axiosIns.interceptors.response.use(
  (response) => {
    nprogress.done()

    if (response.data?.success !== undefined && response.data?.data)
      return { ...response, data: response?.data?.data }

    return response
  },
  (error) => {
    nprogress.done()

    if ([401, 403].includes(error.response?.status)) {
      console.log(error)
      if(error.response.data.errorMessage === "Invalid SMS Code."){
        toast.error('Girdiğiniz kod yanlış, lütfen kodunuzu kontrol ederek tekrar giriş yapınız')
      }else{
        toast.error('Giriş süreniz dolmuştur, lütfen tekrar giriş yapınız')
      }
      router.push({
        path: '/login',
        query: {
          to: !pagesShouldNotRedirect.includes(
            router.currentRoute.value.name?.toString() ?? '',
          )
            ? router.currentRoute.value.fullPath
            : undefined,
        },
      })
    } else if (error.response?.data?.message) {
      const errMessage = error.response?.data?.message

      if (typeof errMessage === 'string') toast.error(errMessage)
      else
        toast.error('Lütfen formu kontrol edin', {
          description: errMessage.join(' • '),
        })
    } else if (error.code === 'ECONNABORTED')
      toast.error('İstek zaman aşımına uğradı, lütfen tekrar deneyiniz')
    else {
      console.error(error)
      toast.error('Bir hata oluştu, lütfen daha sonra tekrar deneyiniz', {
        description: error.response?.data?.errorMessage ?? error.message ?? '',
      })
    }
  },
)

export default axiosIns
